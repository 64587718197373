import React, {Fragment, useState, useEffect, useRef} from 'react';
import {ImageBackground, StyleSheet, Text, View, Image, SafeAreaView, ScrollView, StatusBar, Button, Alert, TextInput, Keyboard, KeyboardAvoidingView, Appearance, Switch  } from 'react-native';
import nice_bike from "./images/nice_bike.jpg";
import { TouchableOpacity  } from 'react-native';

import { Linking } from 'react-native';
import logo from "./images/logo.jpg";
import stolen_frontwheel from "./images/StolenBike_FrontWheel.jpg";

import { getStorage, ref, uploadBytes } from "firebase/storage";
import {useNavigation} from '@react-navigation/core';
import firebase from "firebase/compat/app";
import * as ImagePicker from "expo-image-picker";
import {navigation} from "../App.js"
import { auth, db } from '../firebase'
import { setDoc, doc, collection, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import {  query, where, getDocs, limit, serverTimestamp   } from "firebase/firestore";
import { useIsFocused } from "@react-navigation/native"; 
/* function useChange() {
    const [uploading, setUploading] = React.useState(null);
    function change(value) {
        setUploading(value);
    }
  
    return { change, uploading };
  } */
function ValuableInfoScreen(){
    /* setBikeImage */

    const todoRef = collection(db, "feedbacks");
    const [ uploading, setuploading ] = useState(null);

    const [image, setImage] = useState(null);
    const navigation = useNavigation(); 
    const [feedBack, setFeedback] = useState("");
    const ifloggedin = auth.currentUser?.phoneNumber;
/* useEffect(() => {
    (async () => {
        const galleryStatus = await ImagePicker.requestMediaLibraryPermissionsAsync();
        setHasGalleryPermission(galleryStatus.status === "granted");
    }); 
 }, []); */


 const [theme, setTheme]  = useState(Appearance.getColorScheme());

 const focus = useIsFocused(); 
 useEffect(() => {
     const theme_local = JSON.parse(localStorage.getItem('theme'));
     setTheme(theme_local);
 }, [focus]);
 
 useEffect(() => {
     localStorage.setItem('theme', JSON.stringify(theme));
     console.log(theme)
 }, [theme]);


const pickImage = async () => {
    setuploading(true);
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4,3],
        quality: 1,
        
    });
    const source = {uri: result.uri}; 
    console.log(source);
    setImage(source);
    setuploading(false);
};

const uploadImage = async () => {

    setuploading(true);
    const response = await fetch(image.uri)
    const blob = await response.blob();
    const filename = image.uri.substring(image.uri.lastIndexOf("/")+1)
    var ref = firebase.storage().ref().child(filename).put(blob);

    try {
        await ref;

    } catch (e) {
        console.log(e)
    }
    setuploading(false);
    Alert.alert(
        "Photo uploaded.....!!"
    );
    setImage(null);
};



/* 
if (hasGalleryPermission === false){
    return <Text>No access to internal storage</Text>
} */

const UploadProgress = ({progress}) => {
return(


    <View style={[StyleSheet.absoluteFillObject, styles.container]}>
        <Progress.Bar progress={progress} width={200}/>
    </View>
);
};
const phoneNumber = auth.currentUser?.phoneNumber;
const PublishFeedback=()=>{
    console.log("ok")
    
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    const data = {

    createdAt: timestamp,
    feedback: feedBack,
    phonenumber: phoneNumber,
    
    };
    console.log(data)
    
    todoRef
    .add(data)
    .then(() => {
        //release the new field state
        setFeedback("");
        //release keyboard
        Keyboard.dismiss();

    })

    .catch((error) => {
        alert(error);
    })
/*         console.log(data) */
}




    return(

<ScrollView style={[ theme ? { backgroundColor: '#0F0F0F' } : { backgroundColor: '#F1F1F1' }]}>
<View style={[ theme ? { backgroundColor: '#0F0F0F' } : { backgroundColor: '#F1F1F1' }]}>
  <Text style={[{marginTop: 5, marginLeft: 10}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]} >Dark/Light-Mode</Text>
  <Switch
        trackColor={{false: '#767577', true: '#81b0ff'}}
        ios_backgroundColor="#3e3e3e"
        thumbColor={theme ? '#f5dd4b' : '#f4f3f4'}
        value = {theme}
        onValueChange={() => setTheme(!theme)}
        style={[{marginLeft: 10, marginTop: 5}, theme
              ? { backgroundColor: '#0F0F0F' } : { backgroundColor: '#F1F1F1' }]}
  />
</View>

<KeyboardAvoidingView >
<SafeAreaView style={[styles.container]}>



{/* 
    <View style={{flex:1, justifyContent:'center', marginBottom: 1000}}>
        <Button title="Pick Image" onPress={pickImage} style = {{marginTop: 30}}  />
        {image1 && <Image source={{uri: image1}} style={{flex:1/2}}/>}
    </View> */}



    <TouchableOpacity style={styles.button} onPress={() => {ifloggedin ?   navigation.navigate("LoggedInScreens", { screen: "Min profil"}):   navigation.navigate("LoggedOutScreens", { screen: "Logga in"})}}>
        <Text styles={styles.buttontext}>{ifloggedin? "Tillbaka till alternativ" : "Tillbaka"}</Text>
    </TouchableOpacity>

{/* <!------------ campus----------> */}
<View style = {[styles.campus, theme ? { backgroundColor: '#0F0F0F' } : { backgroundColor: 'white' }]}>
    
    <Text style={[{fontSize:25,}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}>Cykelinformation</Text>
    <Text style={[{textAlign: "center",}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}> {"\n"}Hej!{"\n"} 1. Ramnummer kan hittas på cykelns ram {"\n"}{"\n"}2. Lås alltid din cykelrum till ett fast objekt, föredragligen med ett metallås. Hjul kan enkelt skruvas av. {"\n"}{"\n"}3. Använd kedjeolja åtminstånde en gång varje 6 månader. (Särskillt under vintern) för att minimera frost skada till kedja, bromsar och lås.</Text>
    <Image style={styles.tinyLogo} source={(nice_bike)} />
</View>

<View style = {[{alignItems: "center", marginBottom:100,}]}>
    <Text style={[{fontSize:25, marginTop:10,}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}>Generell statistik</Text>
    <View style = {[styles.campus, theme ? { backgroundColor: '#0F0F0F' } : { backgroundColor: 'white' }]}>
        <Text style={[ theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}>I Europa blir en cykel stulen var tjugonde sekund. Det blir runt 3 millioner cyklar varje år. Omkring 65 000 av dessa cyklar själs i Sverige. Dessa cyklar själs och säljs tillbaka till dig. Mycket av dessa pengar går till medlemmar av organiserad brottslighet som också utövar fler typer av brott. </Text>
        <Image style={styles.tinyLogo} source={(stolen_frontwheel)} />
   {/*      In Europe one bike is stolen every 20 seconds. That is around 3 million bikes per year. Around 65 000 of these bikes are stolen in Sweden. These bikes are stolen and then sold back to you. Much of the money goes to organised crime members who deal in other illegal environments. */}
    </View>
    
</View>



</SafeAreaView>
<View style={{marginBottom:100, padding:55, alignContent: "center", justifyContent: "center", alignItems: "center", backgroundColor: "#1b59b6"}}>
  <Text style={[{fontSize: 25}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}>Kontakt: lostbikes@hotmail.com</Text>
  <Text style={[{fontSize: 25}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}>Plats: Linköping, Sverige</Text>
</View>

</KeyboardAvoidingView>

</ScrollView>
        );


    }

export default ValuableInfoScreen;



const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",

    },

    tinyLogo: {
  
        width: 100,
        height: 100,
        alignItems: "center",
        padding: 0,
        marginTop: 40,
        
    },

    inputView: {
    width: "80%",


    },
    
    TextInput: {
    backgroundColor: 'white',
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10, 
    height: 45,
    marginBottom: 20,
    alignItems: "center",
    marginTop: 5,
    },
    TextInputphone: {
    backgroundColor: 'white',
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10, 
    height: 45,
    marginBottom: 20,
    marginTop: 5,
    },

      
  buttontext: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
},


    button: {
        backgroundColor: '#0782F9',
        width: '60%',
    
        padding: 15,
        borderRadius: 10,
        alignItems: 'center',
        marginTop: 40,
        marginBottom: 30,
    },

    sendVerification: {
    padding: 20,
    backgroundColor: "#3498db",
    borderRadius: 10,
    },

    setFontSizeOne: {
    fontSize: 15 // Define font size here in Pixels
    },

    setFontSizeOne_white: {
        
        fontSize: 15, // Define font size here in Pixels
        color: 'white',

        },

    setFontSizeThree: {
        fontSize: 25, // Define font size here in Pixels
    },

    setFontSizeThree_white: {
        fontSize: 25, // Define font size here in Pixels
        color: 'white',
    },

    setFontSizeFour: {
        fontSize: 30 // Define font size here in Pixels
    },


    course: {
      flex: 100,
      backgroundColor: '#fff',
      alignItems: 'right',
      justifyContent: 'left',
    },

    header: {
      flex: 100,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },

    image: {

    width: '100%', 
    height: '100%',
    flex: 10,
    resizeMode: 'stretch', // or 'stretch'

      },

    scrollView: {
    backgroundColor: 'pink',
    marginHorizontal: 20,
    },   

    text_box: {
        flex: 2,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        padding: 15,
        margin: '2%',
 
    },

    course_row: {
        padding: 20,
        margin: '5%',
        borderRadius: 10,
        backgroundColor: "#fff3f3",
        shadowColor: 'rgba(0,0,0, .4)', // IOS
        shadowOffset: { height: 1, width: 1 }, // IOS
        shadowOpacity: 1, // IOS
        shadowRadius: 1, //IOS
        elevation: 2, // Android
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',

    },

    onpress: {
        shadowColor: 'rgba(0,0,0, .4)', // IOS
        shadowOffset: { height: 1, width: 1 }, // IOS
        shadowOpacity: 1, // IOS
        shadowRadius: 1, //IOS
        backgroundColor: '#fff',
        elevation: 2, // Android
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',


    },

    campus: {

        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        margin: '2%',
        borderRadius:10,
    },

    campus_row: {

        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        margin: '2%',
   
 
    },
    bikelogo: {
        width: 200,
        height: 200,
        borderRadius: 10,
        overflow: "hidden",
        borderWidth: 3,
        borderColor: "red",

  

      },

      buttoncontainer: {
        marginTop: 30,
        marginBottom: 50,
        alignItems: "center",
        justifyContent: "center",
      },

      selectButton: {
        borderRadius: 5,
        width: 150,
        height: 50,
        backgroundColor: "red",
        alignItems: "center",
        justifyContent: "center",
      },
    uploadButton: {
        borderRadius: 5,
        width: 150,
        height: 50,
        backgroundColor: "red",
        alignItems: "center",
        justifyContent: "center",

      },

      buttonText: {
        fontSize: 18,
        fontWeight: "bold",
        color: "white",

        
      },

    imageContainer: {
        marginTop: 30,
        marginBottom: 50,
        alignItems: "center",
        justifyContent: "center",


    },

});