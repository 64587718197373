


// Import the functions you need from the SDKs you need

 // import firebase from 'firebase/compat/app'; 
//import 'firebase/compat/auth';
//import 'firebase/compat/firestore'; 
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyB6_XZ8MTo-z_LtShxCAxPhvRSvvwriMgg",
  authDomain: "stolen-bikes-5d664.firebaseapp.com",
  projectId: "stolen-bikes-5d664",
  storageBucket: "stolen-bikes-5d664.appspot.com",
  messagingSenderId: "256078269191",
  appId: "1:256078269191:web:72401a6a7e4768435cd3a2",
  measurementId: "G-EJ4N1CFLJX"
};

// Initialize Firebase
/* let app;
  
if (firebase.apps.length === 0) { */
 const app = initializeApp(firebaseConfig)
/* } else {
  app = firebase.app();
} */
const firebaseApp = getApp();
const storage = getStorage(firebaseApp, "gs://my-custom-bucket");
/* const db = firestore(app); */
const auth = getAuth(app);

const db = getFirestore(app);

export { db, auth, app, storage };
