
import React, {useState, useEffect} from 'react';
import {Image, StyleSheet, View, Text, Switch} from "react-native";
import { NavigationContainer, DarkTheme, DefaultTheme, useTheme} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';


import LoginScreen from "./pages/signinscreen"; 
import LoginEmailScreen from "./pages/loginEmailScreen"
import  ProfileScreen  from "./pages/profilescreen";
import UploadBikeScreen from './pages/uploadBikeScreen';
import AppLoader from './pages/animations/AppLoader';
import SeePublishedBikesScreen from './pages/seePublishedBikesScreen';


import ValuableInfoScreen from './pages/ValueableInfoScreen';
import AboutUsScreen from "./pages/AboutUsScreen";

import SearchinDataBaseScreen from "./pages/SearchinDataBaseScreen";
import SearchwithmodelScreen from './pages/SearchwithmodelScreen';
import SearchwithCountyScreen from './pages/SearchwithCountyScreen';

import SearchwithLocationandModelScreen from "./pages/SearchwithLocationandModelScreen" 
import SearchFrameScreen from './pages/SearchFrameScreen';
import Sidebar from "./pages/animations/sidebar.js"

import lostbikeslogo from "./pages/images/lostbikeslogoshieldback.png";
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useColorScheme } from 'react-native';
import { auth } from './firebase.js'

const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();

function LoggedInScreens() {

  return (
    <Drawer.Navigator>

    <Drawer.Screen
    name = "Min profil"
    component={ProfileScreen}
    options={styles.optionLogin}
    
    />

    <Drawer.Screen
    name = "Ladda upp en cykel"
    component={UploadBikeScreen}
    options={styles.optionLogin}
    />

    <Drawer.Screen
    name = "Privata rapporter"
    component={SeePublishedBikesScreen}
    options={styles.optionLogin}
    />

    <Drawer.Screen
    name = "LostBikes | Anmäl och sök efter stulna cyklar"
    component={SearchinDataBaseScreen}
    options={styles.optionLogin}
    />

    <Drawer.Screen
    name = "Sök med ramnummer"
    component={SearchFrameScreen}
    options={styles.optionLogin}
    />

{/*     <Drawer.Screen
    name = "Sök med modell"
    component={SearchwithmodelScreen}
    options={styles.optionLogin}
    />

    <Drawer.Screen
    name = "Sök med landskap"
    component={SearchwithCountyScreen}
    options={styles.optionLogin}
    /> */}

{/*     <Drawer.Screen
    name = "Sök med landskap och modell"
    component={SearchwithLocationandModelScreen}
    />   */}

    <Drawer.Screen
    name = "Cykelstatistik om stölder"
    component={ValuableInfoScreen}
    options={styles.optionLogin}
    />

    <Drawer.Screen
    name = "Om oss"
    component={AboutUsScreen}
    options={styles.optionLogin}
    />

  </Drawer.Navigator>
  );
}

function LoggedOutScreens() {
  
  return (
    <Drawer.Navigator>
  
    <Drawer.Screen
    name = "LostBikes | Anmäl och sök efter stulna cyklar"
    component={SearchinDataBaseScreen}
    options={styles.optionLogin}
    />

    <Drawer.Screen
    name = "Logga in"
    component={LoginScreen}
    options={styles.optionLogin}
    />

    <Drawer.Screen
    name = "Logga in med email"
    component={LoginEmailScreen}
    options={styles.optionLogin}
    />

    <Drawer.Screen
    name = "Sök med ramnummer"
    component={SearchFrameScreen}
    options={styles.optionLogin}
    />

{/*     <Drawer.Screen
    name = "Sök med modell"
    component={SearchwithmodelScreen}
    options={styles.optionLogin}
    /> */}

{/*     <Drawer.Screen
    name = "Sök med landskap"
    component={SearchwithCountyScreen}
    options={styles.optionLogin}
    /> */}

{/*     <Drawer.Screen
    name = "Sök med landskap och modell"
    component={SearchwithLocationandModelScreen}
    />   */}

    <Drawer.Screen
    name = "Cykelstatistik om stölder"
    component={ValuableInfoScreen}
    options={styles.optionLogin}
    />

  </Drawer.Navigator>
  );
}

export default function App() {

const [isloading, setisloading] = useState(true)

useEffect(()=>{
  setisloading(true)

  setTimeout(()=> setisloading(false),1000);

},[]);

const scheme = useColorScheme();
const colors = useTheme().colors;
console.log(scheme) 

  return (
<>
    {isloading ? <AppLoader/>:(  
      <NavigationContainer >
            <Stack.Navigator theme={scheme === 'dark' ? DarkTheme : DefaultTheme}>

            <Stack.Screen
            name="LoggedOutScreens"
            component={LoggedOutScreens}
            options={{ headerShown: false }}
          />

            <Stack.Screen
              name="LoggedInScreens"
              component={LoggedInScreens}
              options={{ headerShown: false }}
            />


          {/*        <Stack.Screen name="Feed" component={Feed} /> */}
          </Stack.Navigator>

{/*           /*       <Stack.Navigator initialRouteName='SearchinDataBase'>
        <Stack.Group>
        <Stack.Screen name="Login" component={LoginScreen} options={styles.optionLogin}/>
        <Stack.Screen name="Profile" component={ProfileScreen} options={{ title: 'Alternatives' }}/>
        <Stack.Screen name="SearchinDataBase" component={SearchinDataBaseScreen}  options={styles.optionLogin}/>
        </Stack.Group>
      </Stack.Navigator> */
/*         <Stack.Navigator initialRouteName='SearchinDataBase'>
          <Stack.Screen name="Login" component={LoginScreen} options={styles.optionLogin} 
          />  

          <Stack.Screen name="Profile" component={ProfileScreen} options={{ title: 'Alternatives' }}/> 
          <Stack.Screen name="UploadBike" component={UploadBikeScreen} options={{ title: 'Report a Bike' }}/>
          <Stack.Screen name="SeeReports" component={SeePublishedBikesScreen}  options={{ title: 'Published Reports' }}/>

          <Stack.Screen name="AboutUs" component={AboutUsScreen}  options={{ title: 'About Us' }}/>
          <Stack.Screen name="ValuableInfo" component={ValuableInfoScreen}  options={{ title: 'General Bike Info' }}/>

          <Stack.Screen name="SearchinDataBase" component={SearchinDataBaseScreen}  options={styles.optionLogin}/>

          <Stack.Screen name="SearchinDataBaseFrame" component={SearchFrameScreen}  options={{ title: 'Search with Frame' }}/>
          <Stack.Screen name="SearchinDataBaseModel" component={SearchwithmodelScreen}  options={{ title: 'Search with Model' }}/>
          <Stack.Screen name="SearchinDataBaseCounty" component={SearchwithCountyScreen}  options={{ title: 'Search with County' }}/>
          <Stack.Screen name="SearchinDataBaseCountyandModel" component={SearchwithLocationandModelScreen}  options={{ title: 'Search with Model and County' }}/> 


        </Stack.Navigator>  */ 

/*         <Stack.Navigator>
          <Stack.Group screenOptions={{ presentation: 'modal' }}>
          <Stack.Screen name="Login" component={LoginScreen} />
          <Stack.Screen name="SearchinDataBase" component={SearchinDataBaseScreen} />
          </Stack.Group>
        </Stack.Navigator> */
          /* <Drawer.Navigator>

            <Drawer.Screen
            name = "Anmäl en cykel"
            component={LoginScreen}
            />

            <Drawer.Screen
            name = "Sök efter en cykel"
            key = "sök efter en cykel"
            component={SearchinDataBaseScreen}
            />

            <Drawer.Screen
            name = "Sök med ramnummer"
            component={SearchFrameScreen}
            screenOption ={{drawerlabel: ()=>null  }}
            />

            <Drawer.Screen
            name = "Sök med modell"
            component={SearchwithmodelScreen}
            />

            <Drawer.Screen
            name = "Sök med landskap"
            component={SearchwithCountyScreen}
            />

            <Drawer.Screen
            name = "Sök med landskap och modell"
            component={SearchwithLocationandModelScreen}
            />  

            <Drawer.Screen
            name = "Hur du skyddar en cykel"
            component={ValuableInfoScreen}
            />

            </Drawer.Navigator> */}
    

    </NavigationContainer>
    )}
    </>
  );
}


const styles = StyleSheet.create({

optionLogin: {

  headerTitle: (props) => ( // App Logo
  
  <View style={{flexDirection: "row"}}>
  <Image
    style={{ width: 200, height: 50 }}
    source={require('./pages/images/lostbikeslogowhite.png')}
    resizeMode='contain'
  />
{/*   <Switch
        trackColor={{false: '#767577', true: '#81b0ff'}}
        ios_backgroundColor="#3e3e3e"
       
  /> */}
  </View>
  ),
  
  headerStyle: {
    backgroundColor: '#1b59b6',
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
    fontWeight: 'bold',
    
    }


},
})