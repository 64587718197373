import React, {Fragment, useState, useEffect, useRef} from 'react';
import {ImageBackground, StyleSheet, Text, View, Image, SafeAreaView, ScrollView, StatusBar, Button, Alert, TextInput, Keyboard, KeyboardAvoidingView, Appearance, Switch  } from 'react-native';
import { TouchableOpacity  } from 'react-native';
import { Linking } from 'react-native';
import logo from "./images/logo.jpg";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import {useNavigation} from '@react-navigation/core';
/* import firebase from "firebase/compat/app"; */
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { ActivityIndicator } from 'react-native';
import * as ImagePicker from "expo-image-picker";
import LottieView from "lottie-react-native";
import {navigation} from "../App.js"
import { auth} from '../firebase'
import { setDoc, doc, collection, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import {db, firebase} from "../firebase";
import {  query, where, getDocs, limit, serverTimestamp   } from "firebase/firestore";
import { useIsFocused } from "@react-navigation/native"; 
/* function useChange() {
    const [uploading, setUploading] = React.useState(null);
    function change(value) {
        setUploading(value);
    }
  
    return { change, uploading };
  } */
function AboutUs(){
    /* setBikeImage */

    const todoRef = collection(db, "feedbacks");
    const [ uploading, setuploading ] = useState(null);

    const [image, setImage] = useState(null);
    const navigation = useNavigation(); 
    const [feedBack, setFeedback] = useState("");
/* useEffect(() => {
    (async () => {
        const galleryStatus = await ImagePicker.requestMediaLibraryPermissionsAsync();
        setHasGalleryPermission(galleryStatus.status === "granted");
    }); 
 }, []); */


 const [theme, setTheme]  = useState(Appearance.getColorScheme());

 const focus = useIsFocused(); 
 useEffect(() => {
     const theme_local = JSON.parse(localStorage.getItem('theme'));
     setTheme(theme_local);
 }, [focus]);
 
 useEffect(() => {
     localStorage.setItem('theme', JSON.stringify(theme));
     console.log(theme)
 }, [theme]);



const pickImage = async () => {
    setuploading(true);
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4,3],
        quality: 1,
        
    });
    const source = {uri: result.uri}; 
    console.log(source);
    setImage(source);
    setuploading(false);
};

/* const uploadImage = async () => {

    setuploading(true);
    const response = await fetch(image.uri)
    const blob = await response.blob();
    const filename = image.uri.substring(image.uri.lastIndexOf("/")+1)
    var ref = firebase.storage().ref().child(filename).put(blob);

    try {
        await ref;

    } catch (e) {
        console.log(e)
    }
    setuploading(false);
    Alert.alert(
        "Photo uploaded.....!!"
    );
    setImage(null);
}; */



/* 
if (hasGalleryPermission === false){
    return <Text>No access to internal storage</Text>
} */

const UploadProgress = ({progress}) => {
return(




    <View style={[StyleSheet.absoluteFillObject, styles.container]}>
        <Progress.Bar progress={progress} width={200}/>
    </View>
);
};
const phoneNumber = auth.currentUser?.phoneNumber;


const [users, setUsers] = useState([]);
useEffect(() => {
    const localusers= new Array()
    async function getasyncData(){
        const q = query(collection(db, "feedbacks"), where("phonenumber", "==", phoneNumber))
        const querySnapshot = await getDocs(q);
        return querySnapshot
      }
      const querySnapshot = getasyncData()
/*     collection(db, 'feedbacks', )
    // Filter results set to lower case
    .where('phonenumber', "==", phoneNumber)
    .get() */
    querySnapshot.then(querySnapshot => {

      querySnapshot.forEach(documentSnapshot => {
        console.log('User ID: ', documentSnapshot.id, documentSnapshot.data());
        const {phonenumber, createdAt} = documentSnapshot.data()

        localusers.push({
        id: documentSnapshot.id,
        phonenumber,
        createdAt,
      })
    })
    })
  
    setUsers(localusers)
    console.log("ok1")
    console.log(users)
    
}, [])

const DeleteFeedback = () => {
/*     var chatId = users[0].id; // +639266825843
    var colRef = firebase.firestore()
      .collection('feedbacks')
      .doc(users[0].id)
    colRef.get().then((querySnapshot) => {
        Promise.all(querySnapshot.docs.map((d) => d.ref.delete()));
      }); */
   
 for (let i=0; i<users.length;i++){
         console.log(users[i].id)
      /*  todoRef.doc(users[i].id).delete()  */

/*     let id_to_feedback = users[i].id */
    deleteDoc(doc(db, "feedbacks", users[i].id), {

    }).then(() => {
      console.log("data submitted5");
    }).catch(() => {
      console.log(error);
    })
 }
 navigation.navigate("LoggedInScreens", { screen: "Min profil"})
 navigation.navigate("LoggedInScreens", { screen: "Om oss"})
/*  navigation.replace("Profile")  
 navigation.replace("AboutUs")  */
}
const PublishFeedback=()=>{
    console.log("ok")
    
/*     const timestamp = firebase.firestore.FieldValue.serverTimestamp(); */

    const data = {


    feedback: feedBack,
    createdAt: serverTimestamp(),
    phonenumber: phoneNumber,
    
    };

    
    if (users.length < 4)
    {
        console.log(data)
        console.log(users)

        addDoc(collection(db, "feedbacks"), {
            feedback: feedBack,
            createdAt: serverTimestamp(),
            phonenumber: phoneNumber,
          }).then(() => {
            //release the new field state
            setFeedback("");
            //release keyboard
            Keyboard.dismiss();
            alert("Your feedback has been sent :)")
          }).catch(() => {
            alert(error);
          })

/*         todoRef
        .add(data)
        .then(() => {
            //release the new field state
            setFeedback("");
            //release keyboard
            Keyboard.dismiss();
            alert("Your feedback has been sent :)")
        })

        .catch((error) => {
            alert(error);
        }) */
    /*         console.log(data) */
    }
    else{
        alert("Every user can only send four responses!")
    }
/*     navigation.replace("Profile")  
    navigation.replace("AboutUs")  */
    navigation.navigate("LoggedInScreens", { screen: "Min profil"})
    navigation.navigate("LoggedInScreens", { screen: "Om oss"})
}
    return(

<ScrollView>
<View style={[ theme ? { backgroundColor: '#0F0F0F' } : { backgroundColor: '#F1F1F1' }]}>
  <Text style={[{marginTop: 5, marginLeft: 10}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]} >Dark/Light-Mode</Text>
  <Switch
        trackColor={{false: '#767577', true: '#81b0ff'}}
        ios_backgroundColor="#3e3e3e"
        thumbColor={theme ? '#f5dd4b' : '#f4f3f4'}
        value = {theme}
        onValueChange={() => setTheme(!theme)}
        style={[{marginLeft: 10, marginTop: 5}, theme
              ? { backgroundColor: '#0F0F0F' } : { backgroundColor: '#F1F1F1' }]}
  />
</View>
    <KeyboardAvoidingView>
<SafeAreaView style={[styles.container, theme ? { backgroundColor: '#0F0F0F' } : { backgroundColor: '#F1F1F1' }]}>


{/* 
    <View style={{flex:1, justifyContent:'center', marginBottom: 1000}}>
        <Button title="Pick Image" onPress={pickImage} style = {{marginTop: 30}}  />
        {image1 && <Image source={{uri: image1}} style={{flex:1/2}}/>}
    </View> */}



    <TouchableOpacity style={styles.button} onPress={() => navigation.navigate("LoggedInScreens", { screen: "Min profil"})}>
        <Text styles={styles.buttontext}>Tillbaka till alternativ</Text>
    </TouchableOpacity>

{/* <!------------ campus----------> */}
<View style={[styles.campus, theme ? { backgroundColor: '#0F0F0F' } : { backgroundColor: 'white' }]}>
    
    <Text style={[{fontSize:25,}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}>Om oss</Text>
    <Text style={[{textAlign: "center",}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}>{"\n"}Hej! Jag är glad att du använder denna app för att förhindra brott där cyklar självs och säljs tillbaka till offrens grannar. Pengarna går sedan till kriminella organisationer. {"\n"}{"\n"}Under studerandet på Linköpings Universitet har jag personligen fått tre cyklar och två cykelljus stulna på bara tre år. Det var så jag fick idén att göra denna hemsida.{"\n"}{"\n"}What tycker du om den här appen så långt? Dela gärna tankar och kritik nedan!{"\n"} </Text>
   {/*  I am happy you're using this app to prevent crimes where bikes are being stolen and then sold back to the victims neighbors.
    The money then goes to criminal organizations.{"\n"}{"\n"}While studying at the university I got three bikes and two bike lights stolen during just three years. That is how I got the idea to make this website.{"\n"}{"\n"}What do you think about this app so far? Please share your thoughts below! */}
</View>

<View style={[{alignItems: "center", marginBottom:300,}, theme ? { backgroundColor: '#0F0F0F' } : { backgroundColor: 'white' }]}>
    <Text style={{fontSize:25, marginTop:10,}}>Skicka återkoppling</Text>
    <View style={styles.inputView}>
          <TextInput
            style={styles.TextInput}
            value={feedBack}
            placeholder="Skriv återkoppling"
            placeholderTextColor="#003f5c"
            onChangeText={(text) => setFeedback(text)}
          />
{/*           <View style={styles.TextInputphone}>
            
            <Text style={{color: "#003f5c"}}>Sent from: {firebase.auth().currentUser?.phoneNumber} </Text>

          </View> */}
          <TouchableOpacity style={styles.sendVerification} onPress={() => PublishFeedback()}>
            <Text style={styles.buttonText}>
                Publisera återkoppling
            </Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.removeFeedback} onPress={() => DeleteFeedback()}>
            <Text style={styles.buttonText}>
                Ta bort all återkoppling
            </Text>
          </TouchableOpacity>
          
    </View >
</View>

</SafeAreaView>


</KeyboardAvoidingView>

</ScrollView>
        );


    }

export default AboutUs;



const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",

    },

    inputView: {
    width: "80%",


    },
    TextInput: {
    backgroundColor: 'white',
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10, 
    height: 45,
    marginBottom: 20,
    alignItems: "center",
    marginTop: 5,
    },
    TextInputphone: {
    backgroundColor: 'white',
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10, 
    height: 45,
    marginBottom: 20,
    marginTop: 5,
    },

      
  buttontext: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
},


    button: {
        backgroundColor: '#0782F9',
        width: '60%',
    
        padding: 15,
        borderRadius: 10,
        alignItems: 'center',
        marginTop: 40,
        marginBottom: 30,
    },

    sendVerification: {
    padding: 20,
    backgroundColor: "#3498db",
    borderRadius: 10,
    marginBottom: 10,
    },

    removeFeedback: {
    padding: 20,
    backgroundColor: "#3498db",
    borderRadius: 10,
    marginBottom: 10,
    },

    setFontSizeOne: {
    fontSize: 15 // Define font size here in Pixels
    },

    setFontSizeOne_white: {
        
        fontSize: 15, // Define font size here in Pixels
        color: 'white',

        },

    setFontSizeThree: {
        fontSize: 25, // Define font size here in Pixels
    },

    setFontSizeThree_white: {
        fontSize: 25, // Define font size here in Pixels
        color: 'white',
    },

    setFontSizeFour: {
        fontSize: 30 // Define font size here in Pixels
    },


    course: {
      flex: 100,
      backgroundColor: '#fff',
      alignItems: 'right',
      justifyContent: 'left',
    },

    header: {
      flex: 100,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',

      position: 'relative',
    },

    image: {

    width: '100%', 
    height: '100%',
    flex: 10,
    resizeMode: 'stretch', // or 'stretch'

      },

    scrollView: {
    backgroundColor: 'pink',
    marginHorizontal: 20,
    },   

    text_box: {
        flex: 2,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        padding: 15,
        margin: '2%',
 
    },

    course_row: {
        padding: 20,
        margin: '5%',
        borderRadius: 10,
        backgroundColor: "#fff3f3",
        shadowColor: 'rgba(0,0,0, .4)', // IOS
        shadowOffset: { height: 1, width: 1 }, // IOS
        shadowOpacity: 1, // IOS
        shadowRadius: 1, //IOS
        elevation: 2, // Android
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',

    },

    onpress: {
        shadowColor: 'rgba(0,0,0, .4)', // IOS
        shadowOffset: { height: 1, width: 1 }, // IOS
        shadowOpacity: 1, // IOS
        shadowRadius: 1, //IOS
        backgroundColor: '#fff',
        elevation: 2, // Android
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',


    },

    campus: {
        flex: 10,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        margin: '2%',
        borderRadius:10,
    },

    campus_row: {
        flex: 0,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        margin: '2%',
   
 
    },
    bikelogo: {
        width: 200,
        height: 200,
        borderRadius: 10,
        overflow: "hidden",
        borderWidth: 3,
        borderColor: "red",

  

      },

      buttoncontainer: {
        marginTop: 30,
        marginBottom: 50,
        alignItems: "center",
        justifyContent: "center",
      },

      selectButton: {
        borderRadius: 5,
        width: 150,
        height: 50,
        backgroundColor: "red",
        alignItems: "center",
        justifyContent: "center",
      },
    uploadButton: {
        borderRadius: 5,
        width: 150,
        height: 50,
        backgroundColor: "red",
        alignItems: "center",
        justifyContent: "center",

      },

      buttonText: {
        fontSize: 18,
        fontWeight: "bold",
        color: "white",

        
      },

    imageContainer: {
        marginTop: 30,
        marginBottom: 50,
        alignItems: "center",
        justifyContent: "center",


    },

});