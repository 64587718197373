import React, { Component, Fragment, useState, useEffect } from 'react'

import {StyleSheet, Text, TouchableOpacity, View, ScrollView, Appearance, Switch} from 'react-native'
import Modal from 'react-modal';
import { auth } from '../firebase.js'
import AppLoader from './animations/AppLoader';
import {ImageBackground, Image, SafeAreaView, StatusBar, Button, Alert  } from 'react-native';

import logo from "./images/logo.jpg";

import personphonebike2 from "./images/personphonebike.jpg";
import { useIsFocused } from "@react-navigation/native"; 
/* import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage"; */

import lostbikeslogo from "./images/lostbikeslogoshieldback.png";
import lostbikeslogoshield from "./images/lostbikeslogoshield3part.png"
import * as ImagePicker from "expo-image-picker";
import {serverTimestamp} from "firebase/firestore";


const ProfileScreen = ({ navigation, route }) => {

    
/*     var time = new Date();
    const past = time-2592000000
    console.log(past)
    if(time > past){

    }
    const timestamp = {
        nanoseconds: 0,
        seconds: past
      }
      
      console.log("date"+new Date(timestamp.seconds)) */
 /*    AsyncStorage.setItem("keepLoggedIn", JSON.stringify(true)) */
/*     const data =     AsyncStorage.getItem("keepLoggedIn")

    console.log("profile:"+ data ) */

    const [theme, setTheme]  = useState(Appearance.getColorScheme());

    const focus = useIsFocused(); 
    useEffect(() => {
        const theme_local = JSON.parse(localStorage.getItem('theme'));
        setTheme(theme_local);
    }, [focus]);
    
    useEffect(() => {
        localStorage.setItem('theme', JSON.stringify(theme));
        console.log(theme)
    }, [theme]);

    const setasynclogout = async() => {
/*         AsyncStorage.setItem("keepLoggedIn", "")  
        const data = await AsyncStorage.getItem('keepLoggedIn')

        console.log("if true" + data) */
        navigation.navigate("LoggedOutScreens", { screen: 'Logga in' }),1000
    } 
    
    const handleSignOut = () => {
         setasynclogout()  
/*         AsyncStorage.setItem("keepLoggedIn", "") 
        console.log("123profile:"+ AsyncStorage.getItem("keepLoggedIn"))
        navigation.replace("Login") */


        auth.signOut().then(() => {


          /*   navigation.replace("Login") */
       /*      AsyncStorage.setItem("keepLoggedIn", "") */
        }).catch(error => alert(error.message))

    }

    const handleUploadBike = () => {
        navigation.navigate("LoggedInScreens", { screen: "Ladda upp en cykel"})
/*         navigation.replace("UploadBike") */


    }

    const handleSearchforBike = () => {
        navigation.navigate("LoggedInScreens", { screen: "LostBikes | Anmäl och sök efter stulna cyklar"})
      /*   navigation.replace("SearchinDataBase") */

    }
    const handleSeeReports = () => {
        navigation.navigate("LoggedInScreens", { screen: "Privata rapporter"})
     /*    navigation.replace("SeeReports") */

    }

    const handleAboutUs = () => {
        navigation.navigate("LoggedInScreens", { screen: "Om oss"})
   /*      navigation.replace("AboutUs") */
    }
    
    const handleValuableInfo = () => {
        navigation.navigate("LoggedInScreens", { screen: "Cykelstatistik om stölder"})
    /*     navigation.replace("ValuableInfo") */
    }

    const handleDeleteUser = () => {
        auth.currentUser?.delete().then(
        handleSignOut())
    }


    const [isOpen, setIsOpen] = useState(true);

    const handleClose = () => {
      setIsOpen(false);
      setDecision(false)
      console.log("Cancel Pressed")
    };
  
    const handleConfirm = () => {
      setIsOpen(false);
      setDecision(false)
      handleDeleteUser()
    };  

    const [decision, setDecision] = useState(false)
    const deleteUserAlert = () => {
        Modal.setAppElement(null)
        setDecision(true)
        setIsOpen(true);
        alert("User has been deleted, but published reports are still stored!")
        handleDeleteUser()
       /*  alert(
      "Do you want to delete this account?",
      "Bikes uploaded will not be automatically deleted",
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        { text: "OK", onPress: () => handleDeleteUser() }
      ]
    ); */
    }

    
/*     ComponentDidMount=() => {
        const reference = database().ref("/User");
        reference.on("value", snapshot => {
            console.log("User data: ", snapshot.val());
            this.setState({
                name: snapshot.val(),

            });
        });
    } */


    const [ uploading, setuploading ] = useState(null);

    const [image, setImage] = useState(null);

/* useEffect(() => {
    (async () => {
        const galleryStatus = await ImagePicker.requestMediaLibraryPermissionsAsync();
        setHasGalleryPermission(galleryStatus.status === "granted");
    }); 
 }, []); */





const pickImage = async () => {
    setuploading(true);
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4,3],
        quality: 1,
        
    });
    const source = {uri: result.uri}; 
    console.log(source);
    setuploading(false);
    setImage(source);
};

const uploadImage = async () => {

    {image ? setuploading(true): null}  
    const response = await fetch(image.uri)

    const blob = await response.blob();
    const filename = image.uri.substring(image.uri.lastIndexOf("/")+1)
    var ref = firebase.storage().ref().child(filename).put(blob);

    try {
        await ref;

    } catch (e) {
        console.log(e)

    }
    setuploading(false);
    Alert.alert(
        "Photo uploaded.....!!"
    );
    setImage(null);
};



/* 
if (hasGalleryPermission === false){
    return <Text>No access to internal storage</Text>
} */

const UploadProgress = ({progress}) => {
return(

    <View style={[StyleSheet.absoluteFillObject, styles.container]}>
        <Progress.Bar progress={progress} width={200}/>
    </View>
    );
};




    return (
<Fragment style={[ theme ? { backgroundColor: '#0F0F0F' } : { backgroundColor: '#F1F1F1' }]}>
<View style={[ theme ? { backgroundColor: '#0F0F0F' } : { backgroundColor: '#F1F1F1' }]}>
  <Text style={[{marginTop: 5, marginLeft: 10}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]} >Dark/Light-Mode</Text>

  <Switch
        trackColor={{false: '#767577', true: '#81b0ff'}}
        ios_backgroundColor="#3e3e3e"
        thumbColor={theme ? '#f5dd4b' : '#f4f3f4'}
        value = {theme}
        onValueChange={() => setTheme(!theme)}
        style={[{marginLeft: 10, marginTop: 5}, theme
              ? { backgroundColor: '#0F0F0F' } : { backgroundColor: '#F1F1F1' }]}
  />
</View>
<ScrollView style={[ theme ? { backgroundColor: '#0F0F0F' } : { backgroundColor: '#F1F1F1' }]}>
    <View style={styles.container}>


        <View style={{fontSize: 25, marginTop: 20, alignItems: "center", justifyContent: "center"}}> 
        <Image style={styles.tinyLogo} source={(lostbikeslogo)} />
            <Text style={[{fontSize: 25, marginTop: 20}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}>
                Välkommen till {auth.currentUser?.phoneNumber ? auth.currentUser?.phoneNumber:"login" }s profil 
            </Text>

        </View>
  
{/*         <Text>Email: {auth.currentUser?.email}'s profile</Text> */}
        <TouchableOpacity style={styles.button}
        onPress={handleSignOut}>

        
        <Text styles={styles.buttontext} >Logga ut</Text>
        </TouchableOpacity>
    

{/*     <View style={styles.buttoncontainer}>
        <TouchableOpacity style={styles.selectButton} onPress={pickImage}>
            <Text style={styles.buttonText}>Pick an image</Text>
        </TouchableOpacity>
    </View>
    
        <View style={styles.imageContainer}>
            {image && <Image source= {{uri: image.uri}} style={{width: 300, height:300}}/>}
            <TouchableOpacity style={styles.uploadButton} onPress={(uploadImage) } >

                
                <Text style={styles.buttonText}>Upload image</Text>
            </TouchableOpacity>

        </View> */}

        <View style={styles.header}>
            <ImageBackground source={personphonebike2} style={styles.image} > 

                <TouchableOpacity onPress={() => navigation.navigate("LoggedInScreens", { screen: "Om oss"})}>
                    <Image style={styles.tinyLogo} source={(lostbikeslogo)} />
                </TouchableOpacity>

                <View style={styles.text_box}>
                    <Text style={[styles.setFontSizeThree_white]} >App nyheter</Text>
                    <Text style={[styles.setFontSizeOne_white]}>In app chat kommer snart</Text>

{/*                     <Text style={[styles.setFontSizeOne_white]}>{"\n"}1. Register the stolen or abandoned bikes. Preferably with frame number.{"\n"}{"\n"}2. Search in this database before or after you buy a used bike from someone.{"\n"}{"\n"}
                    3. If the bike is previously stolen, contact the previous owner using this app and then determine whether you should contact the local law enforcement.</Text> */}
    {/*                     <Button title="Press me" onPress={() => Alert.alert('Simple Button pressed')} /> */}
                </View>
            </ImageBackground >

        </View>



{/* <!------------Rules----------> */}
<View style={styles.text_box}>
    <Text style={[styles.setFontSizeThree, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}>LostBikes</Text>
    <Text style={[ theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}> {/* Use the app whenever you find or buy a used bike to check if it is previously stolen to stop criminal organizations! Toghether we can build a safer future! */}
    Använd appen när du hittar eller köper en begagnad cykel för att se om den är stulen sedan tidigare för att stoppa kriminella organisationer! Tillsammans kan vi bygga en säkrare framtid!</Text>
    

    <View className="row">

 
        <TouchableOpacity onPress={handleUploadBike}>
            <View style={styles.course_row}>
                <Text style={[styles.setFontSizeThree]} >Anmäl en förlorad cykel</Text>
    {/*             <Text>{"\n"}Connect phone number and/or email to recieve notifications</Text> */}
            </View>
        </TouchableOpacity>



        <TouchableOpacity onPress={handleSearchforBike}>
            <View style={styles.course_row}>
                <Text style={[styles.setFontSizeThree]}>Sök efter cyklar</Text>
            </View>
        </TouchableOpacity>



        <TouchableOpacity onPress={handleSeeReports}>
            <View style={styles.course_row}>
                <Text style={[styles.setFontSizeThree]}>Hantera dina anmälningar</Text>
            </View>
        </TouchableOpacity>
       

   
        <TouchableOpacity onPress={handleValuableInfo}>
            <View style={styles.course_row_color}>
                <Text style={[styles.setFontSizeThree]}>Stöldstatistik</Text>
            </View>
        </TouchableOpacity>
    
    

               
                <TouchableOpacity onPress={handleAboutUs}>
                <View style={styles.course_row_color}>
                    <Text style={[styles.setFontSizeThree]}>Skicka återkoppling</Text>
   
                    
                </View>
                </TouchableOpacity>
    

            <TouchableOpacity onPress={deleteUserAlert}>
            <View style={styles.course_row_color_delete}>
   

                <Text style={[styles.setFontSizeThree_white]}>Radera kontot </Text>
                <Text style={[styles.setFontSizeOne_white]}>(Dina anmälningar raderas inte)</Text>
            </View>
            </TouchableOpacity>




    </View>
{/* <!--------footer-------> */}

    <Text style={[styles.setFontSizeFour]}></Text>
    <View><Text>{"\n"}</Text></View>
    <View className="icons">
        <View className="fa fa-facebook"></View>
        <View className="fa fa-twitter"></View>
        <View className="fa fa-instagram"></View>
        <View className="fa fa-linkedin"></View>
    </View>
    <View><Text>  </Text>
    <View className="fa fa-heart-o"></View> 
    <Text></Text></View>




</View>



{/*         <Profile/> */}

    </View>

    <View style = {[{marginBottom:100, padding:55, alignContent: "center", justifyContent: "center", alignItems: "center", backgroundColor: "#1b59b6", flexDirection: "row"}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}>
        <Text  style = {[{fontSize: 25}, theme ? { color: '#F1F1F1' } : { color: '#0F0F0F' }]}>Kontakt: lostbikes@hotmail.com{"\n"}Plats: Linköping, Sverige</Text>
        <Image style={{height:100, width: 100}} resizeMode='contain' source={lostbikeslogoshield} />
    </View>

    </ScrollView>
{/*     <View style={styles.profilebar}>
        <Text style={{color:"black", fontSize: 15}}>
            @Stolen Bikes
        </Text>
    </View> */}
{/*     {decision ?     <Modal isOpen={isOpen} >
      <View style={styles.container}>
        <View><Text style={{fontSize: 25, marginTop: 20}}>Are you sure you want to delete this account?</Text></View>
        <TouchableOpacity style={styles.button}  onPress={handleConfirm}><Text style={{fontSize: 25}}>OK       </Text>       </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={handleClose}><Text style={{fontSize: 25}}>Cancel       </Text>     </TouchableOpacity>

      </View>
    </Modal>:null} */}
    {uploading ? <AppLoader/>: null}  
    
</Fragment>

    )
  }

export default ProfileScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },

    button: {
        backgroundColor: '#0782F9',
        width: '60%',
        padding: 15,
        borderRadius: 10,
        alignItems: 'center',
        marginTop: 40,
    },

    profilebar: {
        alignItems: "baseline",
    },

    buttontext: {
        color: 'white',
        fontWeight: '700',
        fontSize: 16,
    },
    tinyLogo: {
        width: 200,
        height: 58,
        bottom: '-5%',
        left: '5%',
        right: 0,
      },
    
    setFontSizeOne: {
        fontSize: 15 // Define font size here in Pixels
        },

    setFontSizeOne_orange: {
    
        fontSize: 15, // Define font size here in Pixels
        color: 'brown',

        },

    setFontSizeOne_white: {
        
        fontSize: 15, // Define font size here in Pixels
        color: 'white',

        },

    setFontSizeThree: {
        fontSize: 25, // Define font size here in Pixels
    },

    setFontSizeThree_white: {
        fontSize: 25, // Define font size here in Pixels
        color: 'white',
    },

    setFontSizeFour: {
        fontSize: 30 // Define font size here in Pixels
    },


    course: {
        flex: 100,
        backgroundColor: '#fff',
        alignItems: 'right',
        justifyContent: 'left',
    },

    header: {

        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        marginTop: 14,
    },

    image: {

    width: 350, 
    height: 400,

    resizeMode: 'stretch', // or 'stretch'



        },

    scrollView: {
    backgroundColor: 'pink',
    marginHorizontal: 20,
    },   

    text_box: {
        flex: 2,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        padding: 15,
        margin: '2%',
    
    },
   /*  backgroundColor: "#f7af57", */
    course_row: {
        padding: 20,
        margin: '5%',
        borderRadius: 10,
        backgroundColor: "#0782F9",
        shadowColor: 'rgba(0,0,0, .4)', // IOS
        shadowOffset: { height: 1, width: 1 }, // IOS
        shadowOpacity: 1, // IOS
        shadowRadius: 1, //IOS
        elevation: 2, // Android
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',

    },
/*     backgroundColor: "#cc8f45", */
    course_row_color: {
    
        padding: 20,
        margin: "5%",
        borderRadius: 10,
        backgroundColor: "#1b59b6",
        shadowColor: 'rgba(0,0,0, .4)', // IOS
        shadowOffset: { height: 1, width: 1 }, // IOS
        shadowOpacity: 1, // IOS
        shadowRadius: 1, //IOS
        elevation: 2, // Android
        justifyContent: 'center',
        alignItems: 'center',
   

    },
    course_row_color_delete: {
   
        padding: 20,
        margin: 10,
        borderRadius: 10,
        backgroundColor: "#533d63",
        shadowColor: 'rgba(0,0,0, .4)', // IOS
        shadowOffset: { height: 1, width: 1 }, // IOS
        shadowOpacity: 1, // IOS
        shadowRadius: 1, //IOS
        elevation: 2, // Android
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',

    },
    onpress: {
        shadowColor: 'rgba(0,0,0, .4)', // IOS
        shadowOffset: { height: 1, width: 1 }, // IOS
        shadowOpacity: 1, // IOS
        shadowRadius: 1, //IOS
        backgroundColor: '#fff',
        elevation: 2, // Android
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',


    },

    campus: {
        flex: 10,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        margin: '2%',
    },

    campus_row: {
        flex: 0,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        margin: '2%',
    
    
    },
    bikelogo: {
        width: 200,
        height: 200,
        borderRadius: 10,
        overflow: "hidden",
        borderWidth: 3,
        borderColor: "red",

    

        },

        buttoncontainer: {
        marginTop: 30,
        marginBottom: 50,
        alignItems: "center",
        justifyContent: "center",
        },

        selectButton: {
        borderRadius: 5,
        width: 150,
        height: 50,
        backgroundColor: "red",
        alignItems: "center",
        justifyContent: "center",
        },
    uploadButton: {
        borderRadius: 5,
        width: 150,
        height: 50,
        backgroundColor: "red",
        alignItems: "center",
        justifyContent: "center",

        },

        buttonText: {
        fontSize: 18,
        fontWeight: "bold",
        color: "white",

        
        },

    imageContainer: {
        marginTop: 30,
        marginBottom: 50,
        alignItems: "center",
        justifyContent: "center",


    },

});

