import React from 'react'
import {View, Text} from "react-native";
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NavigationContainer } from '@react-navigation/native';
import LoginScreen from '../signinscreen';

const Drawer = createDrawerNavigator();
const Stack = createNativeStackNavigator();

export default function sidebar() {
  return (

        <Drawer.Navigator>
            <Drawer.Screen
   
            name = "Login"

            component={LoginScreen}
            
            />

        </Drawer.Navigator>

  )
}
