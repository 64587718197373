
import React, {createContext, useContext, Fragment, useState, useEffect, useRef, setItem} from 'react';
import {ImageBackground, StyleSheet, Text, View, Image, SafeAreaView, ScrollView, Button, Alert, TextInput, KeyboardAvoidingView  } from 'react-native';
import { TouchableOpacity  } from 'react-native';
import {useNavigation} from '@react-navigation/core';
import {db, auth, app} from "../firebase";
import * as ImagePicker from "expo-image-picker";
import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from 'expo-firebase-recaptcha'; 
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, onAuthStateChanged, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink    } from "firebase/auth";

import AppLoader from './animations/AppLoader';
import lostbikeslogo from "./images/lostbikeslogoshieldback.png";
import { StackActions } from '@react-navigation/native';


/* */

const LoginScreen = ({  }) => {


const lengthInput = 6;

const textInput = useRef(null)
const [loginPending, setLoginPending] = useState(false);

const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const navigation = useNavigation()



const [phoneNumber, setPhoneNumber] = useState(""); 
const [code, setCode] = useState("");
const [verificationId, setVerificationId] = useState(null);
/* const recaptchaVerifier = useRef(null); */
const [user, setUser] = useState(null);
/* const [asyncdata, setasyncdata] = useState(false) */

 useEffect(() => { 
  var user_loc = user
  onAuthStateChanged(auth, (user_loc) => { 
     if (user_loc) { 
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
       const uid = user_loc.uid; 
      // ...

/*        navigation.replace('Profile', { name: auth.currentUser?.phoneNumber }) */
       navigation.navigate("LoggedInScreens", { screen: "Min profil"})
    } else { 
      // User is signed out
      // ...
      console.log("Useeffect signout")
     } 
  }); 


/*   checklog()

    console.log( auth().currentUser.phoneNumber)

    const unsubscribe = auth.onAuthStateChanged(user =>{

      if (user) {

        navigation.replace('Profile', { name: auth.currentUser?.phoneNumber })

      }
    })
    return unsubscribe */
   }, [])    

  const setPhoneNumberFunc = (phoneNumber) => {
    var phonenumberNew = phoneNumber
    if (phoneNumber[0] == "0"){
      phonenumberNew = "+46" + phonenumberNew.substring(1);
      setPhoneNumber(phonenumberNew)
    }
    else{
      setPhoneNumber(phoneNumber)
    }
    console.log(phonenumberNew)
    setSendverificationPressed(false)
  }
/*   var phonenumberNew = phoneNumber
  console.log(phonenumberNew)
   if (phoneNumber[0] == "0"){
     phonenumberNew = "+46" + phonenumberNew.substring(1);
     console.log(phonenumberNew)
   }
  phoneProvider.verifyPhoneNumber(phonenumberNew, recaptchaVerifier.current).then(setVerificationId);
  setPhoneNumber("");
 */
/*   const app = initializeApp(firebaseConfig)
  const auth=getAuth(app)  */

  auth.languageCode = 'en-GB';
  var [sendverificationPressed, setSendverificationPressed] = useState(false); 
  const [remove_recaptcha, setRemove_recaptcha] = useState(false);
const sendVerification = () => {
   if (sendverificationPressed == true)
  {
    return
  } 
  document.getElementById("sign-in-button").innerHTML = "";
  setSendverificationPressed(true)
  console.log("ok")

/*    const phoneProvider = new auth().PhoneAuthProvider(); */

   window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
    'size': 'normal',
  'callback': (response) => {

      // reCAPTCHA solved, allow signInWithPhoneNumber.

      console.log("ok2")
      onSignInSubmit();

    },  
    'expired-callback': () => {
      // Response expired. Ask user to solve reCAPTCHA again.
      // ...
      console.log("Do again")
    }

    

  }, auth);


  console.log("hmmmmm")
  window.recaptchaVerifier.render().then((widgetId) => {
    window.recaptchaWidgetId = widgetId;
  
    });
    console.log(window.recaptchaWidgetId)

  


};


const onSignInSubmit = () => {
  const appVerifier = window.recaptchaVerifier;
/*   const recaptchaResponse = grecaptcha.getResponse(recaptchaWidgetId); */
  signInWithPhoneNumber(auth, phoneNumber, appVerifier)
  .then((confirmationResult) => {
    // SMS sent. Prompt user to type the code from the message, then sign the
    // user in with confirmationResult.confirm(code).
    console.log("g ok")
    window.confirmationResult = confirmationResult;
    setRemove_recaptcha(true) 
    alert("Sms sent. Wait for a few seconds please :)")
    // ...

  }).catch((error) => {
    // Error; SMS not sent
    // ...
    console.log("not ok")
    console.log(error)
    const recaptchaResponse = grecaptcha.getResponse(recaptchaWidgetId);
    console.log(recaptchaResponse)
    grecaptcha.reset(window.recaptchaWidgetId);
    alert("There was an error with sending the sms. Try again!")
// Or, if you haven't stored the widget ID:
/*   window.recaptchaVerifier.render().then(function(widgetId) {
  grecaptcha.reset(widgetId);
}); */
  });
}


const confirmCode = () => {
  console.log("confirmcode")
  if (typeof confirmationResult ===  'undefined'){
    return
  }

  confirmationResult.confirm(code).then((result) => {
    // User signed in successfully.
    console.log("confirmcode")
    const userlocal = result.user;
    setUser(userlocal)

    // ...
    alert(
      "Login Successful. Welcome to your profile"
  );
  }).catch((error) => {
    // User couldn't sign in (bad verification code?)
    // ...
    console.log(error)
    alert(
      "Wrong code. Try again!"
  );
  });

/*    const credential = auth.PhoneAuthProvider.credential(
      verificationId,
       code);
   auth().signInWithCredential(credential).then(() => {
       setCode("");
   })
   .catch((error) => {
       //show alert in case of error
       alert(error)
   })
   Alert.alert(
       "Login Successful. Welcome to your profile"
   ); */
}

const checklog=async()=>{

    try {
  
/*         const data = await AsyncStorage.getItem('keepLoggedIn')
  
          console.log("if true" + data) */

    } 
    catch(error) {}
  

};


const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://lostbikes.se/',
    // This must be true.
    handleCodeInApp: true,

  };

  const handleSignup = () =>{
    console.log(auth)
// Confirm the link is a sign-in with email link.
sendSignInLinkToEmail(auth, email, actionCodeSettings)
  .then(() => {
    console.log("ok")
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    window.localStorage.setItem('emailForSignIn', email);
    // ...
  })
  .catch((error) => {
    
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage)
    // ...
  });
}

/*     auth.createUserWithEmailAndPassword(email, password).then(userCredentials => {
      const user = userCredentials.user;
      console.log('Registered with: ', user.email);
    })

    .catch(error => alert(error.message)) */

  

  const handleLogin = () =>{
    
    auth.signInWithEmailAndPassword(email, password).then(userCredentials => {

      const user = userCredentials.user;

      console.log('Logged in with: ', user.email);
    })
    .catch(error => alert(error.message))
  }
  const [hasGalleryPermission, setHasGalleryPermission] = useState(null);
  const [image1, setImage] = useState(null);

/*   useEffect(() => {

      (async () => {
          const galleryStatus = await ImagePicker.requestMediaLibraryPermissionsAsync();
          setHasGalleryPermission(galleryStatus.status === "granted");


      });

  }, []); */

  const pickImage = async () => {
    setLoginPending(true)

      let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4,3],
          quality: 1,
          
          
      });
  console.log(result);
      if (!result.cancelled){
          setImage(result.uri);

      }
      setLoginPending(false)
      if (hasGalleryPermission === false){
        return <Text>No access to internal storage</Text>
    }

  };
  

const setPhoneNumber0to46=(text) => {
  if (text[0] == 0){
    text[0] = "+46";
  }
  else{
    setPhoneNumber(text)
  }

}

const handleSearchforBike = () => {
  navigation.navigate("LoggedOutScreens", { screen: "LostBikes | Anmäl och sök efter stulna cyklar"})
/*  navigation.replace("SearchinDataBase")  */

}

const handleValuableInfo = () => {
  navigation.navigate("LoggedOutScreens", { screen: "Cykelstatistik om stölder"})
/*   navigation.replace("ValuableInfo") */
}

const GotoLoginWithEmail = () => {
  navigation.navigate("LoggedOutScreens", { screen: "Logga in med email"})

}

const GotoLogin = () => {
    navigation.navigate("LoggedOutScreens", { screen: "Logga in"})
  
  }


  return (
<Fragment>
<ScrollView style={styles.container_scroll}>


<View style={styles.container_sms}>
{/*            <FirebaseRecaptchaVerifierModal
            ref={recaptchaVerifier}
            title='Bot filter!'
            firebaseConfig={firebaseConfig}/>    */} 

        <Text style={styles.optText}>
            {/* Login with SMS to report a lost bike */}
            Logga in med Email för att anmäla en förlorad cykel (Email verifiering är fortfarande under bearbetning!)
        </Text>

        <View style={{marginBottom:10, alignItems: "center",  justifyContent:"center"}}>
        <Text  style={{fontSize: 20}}>Kom ihåg att använda den här appen när du köper en begagnad cykel. Ditt bidrag kan hjälpa både polisen och dig själv!</Text>
        <Text style={{ fontSize: 20}} >{"\n"}Ramnummer brukar finnas på cykelns ram! </Text>
        </View>
        <div id='sign-in-button'>
          
          </div> 
  {/*       Remeber to use this app when finding or buying a used bike. Your contribution can both help the police and you! */}
        

        <Image style={styles.image} source={require("./images/lostbikeslogoshieldback.png")} />

    <View style={styles.inputView}>
    <TextInput
      style={styles.TextInput}
      value={email}
      placeholder="Email"
      placeholderTextColor="#003f5c"
      onChangeText={(text) => setEmail(text)}
    />


    <TextInput
      style={styles.TextInput}
      value={password}
      placeholder="Password"
      placeholderTextColor="#003f5c"
      secureTextEntry
      onChangeText={(text) => setPassword(text)}
      />
  </View>

    <View style={styles.buttoncontainer}>
{/*         <TouchableOpacity>
            <Text style={styles.forgot_button}>Forgot Password?</Text>
        </TouchableOpacity> */}

        <TouchableOpacity style={styles.loginBtn} onPress={handleLogin}>
            <Text style={styles.loginText} placeholder = "Email" >LOGIN</Text>
        </TouchableOpacity>

        <TouchableOpacity style={[styles.loginBtn, styles.buttonoutline]} onPress={handleSignup}>
            <Text  style={styles.buttonoutlinetext} secureTextEntry>Register</Text>
        </TouchableOpacity> 
    </View>



    <View style={{marginTop: "30px"}}>

        <TouchableOpacity style={styles.emailLoginButton} onPress={GotoLogin}>
          <Text style={styles.buttonText}>
              Logga in med sms  
          </Text>
      </TouchableOpacity>
    </View>
{/*     <Text style={{fontSize:35, marginTop:30,}}>Procedure: </Text>  */}


</View>
 <View style={{marginBottom:0, alignItems: "center",  justifyContent:"center", marginLeft:16, marginRight:16,}}>{/* 
<Text >{"\n"}1. Register the stolen or abandoned bikes. Preferably with frame number.{"\n"}{"\n"}2. Search in this database before or after you buy a used bike from someone. {"\n"}{"\n"}
                    3. If the bike is previously stolen, contact the previous owner using this app and then determine whether you should contact the local law enforcement.</Text>  */}
                    <Text style={{fontSize: 30, fontWeight: "bold", marginTop: 30}}>Statistik om stölder</Text>

                    <Text  style={{marginBottom:10, fontSize: 20}}>{"\n"}Studenter på universitet har en 53% chans att få minst en cykel stulen. Pengarna går sedan till kriminella organisationer istället för till lagliga verksamheter. Vår civil courage kan därför både förhindra organiserad brottslighet och återföra cyklar till sina rättmätiga ägare.
                    {/* University students have a 53% change of having one bike stolen. The money then goes to criminal organizations. Showing civil courage might therefore both prevent organized crimes and return bikes to their rightful owners. */}
                    </Text>
{/*   <View style={styles.square}>
    <View style={styles.square_inside}>
    <Text  style={{fontSize:20, marginLeft:10,marginRight:10}}>You can find your frame number on your bikes frame. Every bike has one! </Text>
    </View>
  </View>
  <View style={styles.square}>
    <View style={styles.square_inside}>
    <Text style={{fontSize:20, marginLeft:10,marginRight:10}}>There is a 53% risk for a student to have at least one bike stolen over four years! Using this app for 2 minutes might save you the cost of that bike </Text>
    </View>
  </View>
  <View style={styles.square}>
    <View style={styles.square_inside}>
    <Text style={{fontSize:20, marginLeft:10,marginRight:10}}>Use this app whenever you buy a used bike to see if it is previously stolen! </Text>
       </View>
  </View> */}

</View>

{/* <View style={{marginBottom:0, alignItems: "center",  justifyContent:"center", marginLeft:16, marginRight:16,}}>
<Text>You can find your frame number on your bikes frame. Every bike has one! </Text>
</View> */}

<View style={styles.course_row}>
        <TouchableOpacity onPress={handleSearchforBike}>
                <Text style={{fontSize:25, color: "white"}}>Sök efter en cykel</Text>
                <Text></Text>
        </TouchableOpacity>
</View>
<View style={styles.course_row}>
        <TouchableOpacity onPress={handleValuableInfo}>
                <Text style={{fontSize:25, color: "white"}}>Cykel statistik och information</Text>
                <Text></Text>
        </TouchableOpacity>
</View>

<View style={{alignItems: "center", borderColor: "#1B59B6", borderWidth: 10, marginTop: 10, marginBottom:25}}>
  <Text style={{fontSize: 50, marginBottom: 20}}>
    Sponsorer
  </Text>
  <Text>Visit Bikelease to rent bikes   </Text>
  <TouchableOpacity style= {{marginBottom: 5}}onPress={() => Linking.openURL("https://bikelease.se/?utm_source=Google&utm_medium=search&utm_campaign=Brand&gclid=CjwKCAjwloynBhBbEiwAGY25dEto6VgovA29Cj0vbTI4025GKQFgtFuGcee_yGPVquSjqZgWqJQg8RoCcJwQAvD_BwE")}><Image style={{margin: 5, height: 40, width: 200}} source={require("./images/bikelease_logo.png")} /></TouchableOpacity>
</View> 


<View style={{marginBottom:100, padding:55, alignContent: "center", justifyContent: "center", alignItems: "center", backgroundColor: "#1b59b6"}}>
  <Text style={{fontSize: 25}}>Kontakt: lostbikes@hotmail.com</Text>
  <Text style={{fontSize: 25}}>Plats: Linköping, Sverige</Text>
</View>


{/*     <View style={{flex:1, justifyContent:'center', marginBottom: 1000}}>
        <Button title="Pick Image" onPress={() => pickImage()} style = {{marginTop: 30}}  />
        {image1 && <Image source={{uri: image1}} style={{flex:1/2}}/>}
    </View> */}
{/* 
 <KeyboardAvoidingView style={styles.container} behavior="padding">
<Image style={styles.image} source={require("./images/logo.jpg")} />

  <View style={styles.inputView}>
    <TextInput
      style={styles.TextInput}
      value={email}
      placeholder="Email"
      placeholderTextColor="#003f5c"
      onChangeText={(text) => setEmail(text)}
    />


    <TextInput
      style={styles.TextInput}
      value={password}
      placeholder="Password"
      placeholderTextColor="#003f5c"
      secureTextEntry
      onChangeText={(text) => setPassword(text)}
      />
  </View>



<View style={styles.buttoncontainer}>
  <TouchableOpacity>
    <Text style={styles.forgot_button}>Forgot Password?</Text>
  </TouchableOpacity>

  <TouchableOpacity style={styles.loginBtn} onPress={handleLogin}>
    <Text style={styles.loginText} placeholder = "Email" >LOGIN</Text>
  </TouchableOpacity>

  <TouchableOpacity style={[styles.loginBtn, styles.buttonoutline]} onPress={handleSignup}>
    <Text  style={styles.buttonoutlinetext} secureTextEntry>Register</Text>
  </TouchableOpacity> 

 <TouchableOpacity style={styles.tinyLogo} onPress={() => {AsyncStorage.setItem("keepLoggedIn", JSON.stringify(true)),   navigation.replace("Profile")}}>
  
    <Image source={logo} resizeMode="stretch" style={styles.text_box_white}/>
 

  </TouchableOpacity> 

</View>


</KeyboardAvoidingView>  
  */}
  

</ScrollView>
{loginPending ? <AppLoader/>: null}  
</Fragment>


  )
}
export default LoginScreen

const styles = StyleSheet.create({
  container_scroll: {
    flex: 1,


  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",

  },

  container_sms: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",

  },

  containerInput: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  cellView: {
    paddingVertical: 11,
    width: 40,
    height: 5,
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1.5
  },

  cellText: {
    textAlign: "center",
    fontSize: 16,
  },

  tinyLogo: {
    width: "60%",
    height: "20%",
    alignItems: "center",
    padding: 0,
    marginTop: 40,
    
  },
  image: {
    marginTop: 20,
    width: 300,
    height: 80,
    padding: 10,
    marginBottom: 20,
  },
  text_box_white: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  
    padding: 15,
    margin: '2%',
    color: "white",
},

buttoncontainer: {
width: "60%",
justifyContent: "center",
alignItems: "center",
marginTop: 40,
},

inputView: {
  width: '80%',

},

TextInput: {
  backgroundColor: 'white',
  borderRadius: 10,
  paddingHorizontal: 15,
  paddingVertical: 10, 
  height: 45,
  marginBottom: 20,
  alignItems: "center",
  marginTop: 5,
},

forgot_button: {
  height: 30,
  marginBottom: 30,
},

loginText: {
  color: "white",
  fontWeight: "700",
  fontSize: 16,

},

loginBtn: {
  padding: 15,
  width: "100%",
  borderRadius: 10,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: '#0782F9',
},

buttonoutline: {
  backgroundColor: 'white',
  borderColor: '#0782F9',
  borderWidth: 2,
  marginTop: 5,
},

buttonoutlinetext: {
  color: '#0782F9',
  bordercolor: '#0782F9',
  fontSize: 16,
  fontWeight: "700",
},

textInput: {
  paddinTop: 40,
  paddingBottom: 20,
  paddingHorizontal: 20,
  fontSize: 24,
  borderBottomColor: "black",
  marginBottom:20,
  borderBottomWidth:2,
  textAlign:"center",
  color:"black",
},

sendVerification: {
  padding: 20,
  backgroundColor: "#3498db",
  borderRadius: 10,
},
  sendCode: {
    padding:20,
    backgroundColor:"#1b59b6",
    borderRadius: 10,
  },

  emailLoginButton: {
    padding:20,
    backgroundColor:"#444A75",
    borderRadius: 10,
  },

  buttonText: {
    textAlign: "center",
    color: "#fff",
    fontWeight: "bold",


  },

  optText: {
    fontSize: 35,
    fontWeight: "bold",
    color: "black",
    marginTop: 25,
    marginBottom: 20,
  },
/*   f7af57 */
  course_row: {
    padding: 20,
    margin: '5%',
    borderRadius: 10,
    backgroundColor: "#3498db",
    shadowColor: 'rgba(0,0,0, 0.4)', // IOS
    shadowOffset: { height: 1, width: 1 }, // IOS
    shadowOpacity: 1, // IOS
    shadowRadius: 1, //IOS
    elevation: 2, // Android
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',

},

square: {
  padding: 5,
  marginBottom: 20,
  borderRadius: 10,
  backgroundColor: "#3498db",

  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',

},
square_inside: {
  margin: 5,
  padding: 5,
  backgroundColor: "white",
  borderRadius: 20,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',

},

});